import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

const ThreeJSScene = () => {
  const refContainer = useRef(null);
  const modelStl = new URL('../Static/albaniaglossy.stl', import.meta.url).href;
  const scene = new THREE.Scene();
  scene.background = new THREE.Color(0xFF1111);

  useEffect(() => {
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const camera = new THREE.PerspectiveCamera(25, sizes.width / sizes.height, 5, 10000);
    camera.position.x = 1000;
    camera.position.y = 0;
    camera.position.z = 500;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(sizes.width, sizes.height);
    refContainer.current.appendChild(renderer.domElement);

    // Lights
    const pointLight1 = new THREE.PointLight(0xffffff, 1);
    pointLight1.position.set(1000, 500, 100);
    scene.add(pointLight1);

    const pointLight2 = new THREE.PointLight(0xffffff, 0.5);
    pointLight2.position.set(-500, 100, -300);
    scene.add(pointLight2);

    // Material
    const material = new THREE.MeshStandardMaterial({ flatShading: true, side: THREE.DoubleSide });

    // Load STL
    const stlLoader = new STLLoader();
    stlLoader.load(modelStl, function (geometry) {
      const mesh = new THREE.Mesh(geometry, material);
      geometry.computeVertexNormals();
      mesh.geometry.center();
      scene.add(mesh);
  
    });

    // Controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.03;
    controls.screenSpacePanning = false;
    controls.autoRotate = true;
    controls.autoRotateSpeed = 2;

    // Resize listener
    const onWindowResize = () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
      renderer.setSize(sizes.width, sizes.height);
    };
    window.addEventListener('resize', onWindowResize);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      controls.update(); // Only required if controls.enableDamping = true, or if controls.autoRotate = true.
      renderer.render(scene, camera);
    };
    animate();

    // Cleanup
    return () => {
      window.removeEventListener('resize', onWindowResize);
      refContainer.current.removeChild(renderer.domElement);
    };
  }, [modelStl]);

  return <div ref={refContainer} style={{
    position: 'fixed', // or 'absolute'
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1, // Ensure this is behind other content
  }}/>;
};

export default ThreeJSScene;
