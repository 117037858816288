import React from 'react'
import Header from '../Components/Header';
import Three from './three'
import { RandomReveal } from 'react-random-reveal'

function animateTitle(Title = "$ALBANIA", delay = 20) {
  let counter = 0;
  let direction = true;

  setInterval(function() {
    counter = (direction) ? ++counter : --counter;

    if (counter === 0 || counter === Title.length) {
      direction = !direction;
    }

    document.title = (counter === 0) ? " " : Title.slice(0, counter);
  }, delay);
}

animateTitle()


export default function Home() {

    


    
  return (
    <div>
      <Three />
      <Header/>  
      <div className='container'>
      <p className='main-text'><span></span></p>
      </div>
      <div className='container2'>
      <p className='pump-button'><a className='pump-button' href='https://www.pump.fun/6iFm5rNsmhGWbFSEmGfVRtzzc7FmLJNUogoMsi9CEPWz' target='_blank'>PUMP.FUN</a><span></span></p>
      </div>
    </div>
  )
}