import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from 'react-router-dom';
import LinkIcon from '../Static/link.svg';
import ballzPic from '../Static/albania.svg'
import Telegram from '../Static/telegram.svg';
import Twitter from '../Static/twitter.svg';
import { Cross as Hamburger } from 'hamburger-react'
  const dropIn = {
    hidden: {
      y: "-200vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.25,
      },
    },
    exit: {
      y: "-200vh",
      opacity: 0,
      transition: {
        duration: 0.25,
      },
    },
  };

  export default function App() {
    const modalRef = useRef();
    const [modal, setModal] = useState(false);
    
	const toggleModal = () => {
		setModal(!modal);
	  };
	
	  if(modal) {
		document.body.classList.add('active-modal-2')
	  } else {
		document.body.classList.remove('active-modal-2')
	  }

	  const [isActive, setIsActive] = useState(false);
	  const onClick = () => setIsActive(!isActive)

	  function openNewModal(){
		modalRef.current.open()
	  }


    return (
        <div>
        <Modal ref={modalRef}></Modal>
        <div className="header">
          <img src = {ballzPic} alt="ballz" className='ballzImage'></img>

          <div className="header-right">
            <a href="https://t.me/+R9EsflsPHQRjZWIx" target='_blank'><button className='btn-light'><span><img src = {LinkIcon} alt="telegram"></img> <span id="padding">Telegram</span></span></button></a>
            <a href="https://twitter.com/AlbaniaSolana" target='_blank'><button className='btn-light'><span><img src = {LinkIcon} alt="twitter"></img> <span id="padding">Twitter</span></span></button></a>
            <div className='burger-mobile' onClick={() => { toggleModal(); openNewModal();}}><Hamburger toggled={modal} toggle={setModal} /></div>
          </div>
        </div>
        
            {modal && (
            <div>
            <div className="header-mobile">
                <Link to="/"><button className='ballz-btn-mobile'>ALBANIA</button></Link>
            </div>
            </div>
            )}
            {modal && (
            <div className="overlay-2"></div>
            )}
    
                </div>
            );
        }
  const Modal = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);
    const [isColor, setColor] = useState(false);

    const toggleColor = () => {
      setColor(!isColor);
    };
  
	  const [isActiveeee, setIsActiveeee] = useState(false);
	  const onClick4 = () => setIsActiveeee(!isActiveeee);

    useImperativeHandle(ref, () => {
      return {
        open: () => setOpen(!open),
        close: () => setOpen(!open)
      };
    });
  
    return (
      <AnimatePresence>
        {open && (
          <>

            <motion.div
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
              className="modal-content-wrapper"
            >
              <motion.div
                className="modal-content"
              >
          <div className="popup-content">
                    <a href={"https://www.pump.fun/6iFm5rNsmhGWbFSEmGfVRtzzc7FmLJNUogoMsi9CEPWz"}><p className='mobile-button'>PUMP.FUN</p></a>
                    <a href={"https://albania.al/"}><p className='mobile-button'>ALBANIA GOVERNMENT</p></a>
            </div>
                      <div className='icons-mobile'>
                      <a href={"https://t.me/+R9EsflsPHQRjZWIx"}><button className='socail-button' id="button-1"><img src = {Telegram} alt="telegram"></img></button></a>
                      <a href={"https://twitter.com/AlbaniaSolana"}><button className='socail-button'><img src = {Twitter} alt="twitter"></img></button></a>
                      </div>

              </motion.div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    );
  });