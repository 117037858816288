import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './Pages/home';
import './Styles/Styles.css'

function App() {
  return (

    <Router>
   <div className='App'>
      <div>
       <Switch>
         <Route exact path="/"><Home/></Route>
       </Switch>
      </div>
    </div>
    </Router>
 
  );
}

export default App;